:root {
    /* INPUT */
    --search-input-stroke: null;
    --search-input-stroke-active: #2B2B2B;
    --search-input-color: #333333;
    --search-slot-rolling-effect-color: #a9a9a9;
    --search-input-icon-x: #757575;
    --search-title-bottom-margin: 18px;
    --margin-input-icon-magnify: 12px;

     /* ICON */
     --search-icon-arrow: url('../../search/image/intercasino-com/arrow.svg');
     --search-icon-magnify: url('../../search/image/intercasino-com/magnify.svg');
     --search-icon-close: url('../../search/image/intercasino-com/close.svg');
     --search-icon-clear: url('../../search/image/intercasino-com/clear.svg');

    /* BACKGROND */
    --search-bg-placeholder: #DBDBDB;
    --search-background-radius: 8px;
    --search-background-mobile-radius: 8px;
    --search-overlay-backdrop-color: #000;

    /* GAME GRIDS */
    --search-results-grid: repeat(2, 1fr);
    --search-results-sm-grid: repeat(3, 1fr);
    --search-results-md-grid: repeat(4, 1fr);
    --search-results-lg-grid: repeat(5, 1fr);
    --search-carousel-width: 164px;
    --search-game-tile-height: 220px;

    /* FILTER DROPDOWN */
    --search-filter-bg-color: #fff;
    --search-filter-stroke: #BFBFBF;
    --search-filter-text: #333333;
    --search-filter-icon: #333333;
    --search-input-border-width: 1px;

    /* PADDINGS */
    --search-result-padding-top: 1em;
}